// merge
export const mergeOptions = function ($vm, options) {
  const defaults = {}
  for (const i in $vm.$options.props) {
    defaults[i] = $vm.$options.props[i].default
  }
  const _options = Object.assign({}, defaults, options)
  for (const i in _options) {
    $vm[i] = _options[i]
  }
}

export const initializeProps = function ($vm) {
  for (const i in $vm.$options.props) {
    $vm[i] = $vm.$options.props[i].default
  }
}
