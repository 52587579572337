import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import user from './modules/user'
import order from './modules/order'
import games from './modules/games'
import { localCache, sessionCache } from '@/common/storage'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        app,
        user,
        order,
        games
    },
    getters: {
        getTabbarStatus: state => state.app.isTabbar || (localCache.get('tabbarStatus') || false),
        getTabValue: state => Number(state.app.tabValue || (Number(sessionCache.get('tabValue') || 0)))
    }
})
