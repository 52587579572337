import drawLogo from './module/drawLogo'
import transfer from './module/transfer'

export default function (Vue) {
  /**
   * v-draw-logo 打水印
   */
  Vue.directive('draw-logo', drawLogo)
  /**
   * 瞬间转移
   * v-transfer={(Node|string|Boolean)} [node=document.body] DOM Node, CSS selector, or Boolean
   */
  Vue.directive('transfer', transfer)
}
