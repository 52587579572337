import wx from '@/assets/js/wx-jssdk'
import wxSdk from '@/assets/js/jssdk'
function plugin (Vue) {
  if (plugin.installed) {
    return
  }
  Vue.wx = (() => {
    return wx() || wxSdk
  }).call(this)
  Object.defineProperties(Vue.prototype, {
    $wx: {
      get () {
        return wx() || wxSdk
      }
    }
  })
}

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(plugin)
}
export default plugin
