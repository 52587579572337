/* eslint-disable space-before-function-paren */
import Vue from 'vue'
import * as types from '../action-type'
// import { deepClone } from '@/common/util'
import { localCache, sessionCache } from '@/common/storage'
import { newApi, baseURL } from '@/config'

// 路由切换
const UPDATE_DIRECTION = 'UPDATE_DIRECTION'

const state = {
    direction: 'forward',
    isTabbar: false,
    tabValue: 0,
    isPC: false,
    hideLabors: true // 劳务管理菜单隐藏
}
const mutations = {
    [UPDATE_DIRECTION]: (state, params) => {
        state.direction = params.direction
    },
    setTabbarStatus: (state, value) => {
        localCache.set('tabbarStatus', value)
        state.isTabbar = value
    },
    setTabValue: (state, value) => {
        sessionCache.set('tabValue', `${value}`)
        state.tabValue = `${value}`
    },
    setIsPC: (state, value) => {
        state.isPC = value
    },
    setLabors: (state, value) => {
        state.hideLabors = value
    }
}

const actions = {
    [types.GET_INDUSTORY] ({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            Vue.req
                .get('/metadata/industory', {
                    params: {},
                    baseURL: newApi()
                })
                .then((res) => {
                    resolve(res)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    [types.SMS_SEND] ({ commit, dispatch }, phone) {
        return new Promise((resolve, reject) => {
            Vue.req.post(`/sms/send?phone=${phone}`, null, {
                // baseURL: portalURL()
                baseURL: newApi()
            }).then((res) => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            })
        })
    },
    [types.Get_ALLCITY] ({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            Vue.req
                .get('/citys', {
                    params: {},
                    baseURL: baseURL()
                })
                .then((res) => {
                    resolve(res)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    [types.GET_LABORS_HIDE]({ commit, dispatch }, meetingId) { // 查询劳务信息
        return new Promise((resolve, reject) => {
          Vue.req.get('guest/meetinglabor', {
            baseURL: newApi(),
            params: {
              meetingId
            }
          }).then(res => {
            commit('setLabors', !res)
            resolve(res)
          }).catch(err => {
            reject(err)
          })
        })
    }
}

export default {
    state,
    mutations,
    actions
}
