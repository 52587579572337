/* eslint-disable space-before-function-paren */
import Vue from 'vue'
import * as types from '../action-type'
import { newApi, api } from '@/config'

const actions = {
    [types.JOIN_GAME]({ commit, dispatch }, data) { // 加入游戏
        return new Promise((resolve, reject) => {
            Vue.req.post('games/join', data, {
                baseURL: newApi()
            }).then((res) => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            })
        })
    },
    [types.SUBMIT_GAME_RESULT]({ commit, dispatch }, data) { // 提交游戏结果
        return new Promise((resolve, reject) => {
            Vue.req.put('games/play', data, {
                baseURL: newApi()
            }).then((res) => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            })
        })
    },
    [types.GET_GAME_RANK]({ commit, dispatch }, data) { // 提交游戏结果
        return new Promise((resolve, reject) => {
            Vue.req.get(`games/${data.id}/player/rank`, {
                baseURL: newApi(),
                params: data
            }).then((res) => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            })
        })
    },
    [types.GET_PLAYER_INFO]({ commit, dispatch }, data) { // 获取玩家信息
        return new Promise((resolve, reject) => {
            Vue.req.get('games/player', {
                baseURL: newApi(),
                params: data
            }).then((res) => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            })
        })
    },
    [types.GET_GAME_INFO]({ commit, dispatch }, id) { // 获取游戏详情
        return new Promise((resolve, reject) => {
            Vue.req.get(`games/${id}`, {
                baseURL: api()
            }).then((res) => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            })
        })
    }
}

export default {
  actions
}
