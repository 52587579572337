import { getToken, removeToken } from '@/common/auth'
import { getUrlParam } from '@/common/myUtil'
import { baseURL } from '@/config'
import { router } from '@/routes/router'
import Axios from 'axios'

function plugin (Vue) {
  if (plugin.installed) {
    return
  }
  const instance = Axios.create({
    baseURL: baseURL(),
    timeout: 5000 * 2,
    headers: {
      // eslint-disable-next-line
      'Accept': 'application/json',
      'Content-type': 'application/json'
    },
    validateStatus (status) {
      return status >= 200 && status < 300
    }
  })
  // 请求拦截
  instance.interceptors.request.use((config) => {
    if (config.method === 'post' && config.url !== 'file') {
        config.data = JSON.stringify(config.data)
    }
    // 设置请求头
    config.headers['Content-type'] = 'application/json'
    const token = getToken()
    if (token) {
      // eslint-disable-next-line
      config.headers['Authorization'] = 'bearer ' + token
    }
    return config
  }, (err) => {
    return Promise.reject(err)
  })
  // 请求结束
  instance.interceptors.response.use((res) => {
    if (res.status === 200) {
      return res.data
    }
  }, (err) => {
    console.log(err.response)
    if (err.response) {
      if (err.response.status === 400) {
        if (err.response.data.code === 100001 && err.response.data.detail) {
          const data = err.response.data.detail
          return Promise.reject(data)
        }
      }
      if (err.response.status === 401) {
        if (Vue.loading.isVisible()) {
          Vue.loading.hide()
        }
        removeToken()
        router.replace({
            path: '/login',
            query: {
              meetingId: getUrlParam('meetingId') || 0
            }
        })
        return new Promise(() => ({}))
      } else if (err.response.status === 404) {
        Vue.toast.show((err.response.data && err.response.data.error) || '接口不存在')
      }
    }
    if (Axios.isCancel(err)) {
      return new Promise(() => ({}))
    }
    return Promise.reject(err)
  })

  Vue.req = (() => {
    return instance
  }).call(this)

  Object.defineProperties(Vue.prototype, {
    $req: {
      get () {
        return instance
      }
    }
  })
}

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(plugin)
}

export default plugin
