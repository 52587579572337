<template>
  <div>
    <transition name="mask">
      <div class="confirm" v-show="showValue"></div>
    </transition>
    <transition name="dialog">
      <div class="dialog" v-show="showValue">
        <div class="dialog-title">{{title || '提示'}}</div>
        <div class="dialog-hd">
          <div class="text" v-html="content"></div>
        </div>
        <div class="dialog-ft">
          <span @click="_onCancel">{{cancelText || '取消'}}</span>
          <span @click="_onConfirm">{{confirmText || '确定'}}</span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  export default {
    name: 'confirm',
    props: {
      title: String,
      content: String,
      cancelText: String,
      confirmText: String
    },
    data () {
      return {
        showValue: false
      }
    },
    methods: {
      _onConfirm () {
        if (!this.showValue) {
          return
        }
        this.showValue = false
        this.$emit('on-confirm')
      },
      _onCancel () {
        if (!this.showValue) {
          return
        }
        this.showValue = false
        this.$emit('on-cancel')
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "~@/assets/css/transition.scss";

  .confirm {
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
  }

  .dialog {
    position: fixed;
    display: table;
    z-index: 5000;
    width: 80%;
    max-width: 300px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    background-color: #FFFFFF;
    text-align: center;
    border-radius: 3px;
    overflow: hidden;

    .dialog-title {
      font-size: 18px;
      padding: 16px 19px 6px;
    }

    .dialog-hd {
      padding: 0 24px 12px;
      min-height: 40px;
      font-size: 15px;
      line-height: 1.3;
      word-wrap: break-word;
      word-break: break-all;
      color: #999999;
    }

    .dialog-ft {
      font-size: 18px;
      line-height: 48px;
      display: flex;
      position: relative;

      span {
        flex: 1;

        &:last-child {
          color: #2FC6A3;
        }

        &:first-child {
          color: #353535;
        }
      }
    }

    .dialog-ft:after {
      content: " ";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      height: 1px;
      border-top: 1px solid #D5D5D6;
      color: #D5D5D6;
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
      -webkit-transform: scaleY(0.5);
      transform: scaleY(0.5);
    }

    .dialog-ft:before {
      content: " ";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      width: 1px;
      height: 48px;
      margin: auto;
      border-left: 1px solid #D5D5D6;
      color: #D5D5D6;
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
      -webkit-transform: scaleX(0.5);
      transform: scaleX(0.5);
    }
  }
</style>
