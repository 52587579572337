<template>
  <div class="sub-page-layout">
    <div class="sub-page-layout-left">
      <div @click="detail(menus[0])" v-if="menus[0]" class="sub-page-1"
        :style="background(0)">
      </div>
      <div class="sub-page-2">
        <div @click="detail(menus[1])" v-if="menus[1]"
        :style="background(1)">
        </div>
        <div @click="detail(menus[2])" v-if="menus[2]"
        :style="background(2)">
        </div>
      </div>
      <div @click="detail(menus[3])" class="sub-page-3" v-if="menus[3]"
      :style="background(3)">
      </div>
    </div>
    <div class="sub-page-layout-right">
      <div class="sub-page-2">
        <div @click="detail(menus[4])" v-if="menus[4]"
        :style="background(4)">
        </div>
        <div @click="detail(menus[5])" v-if="menus[5]"
        :style="background(5)">
        </div>
      </div>
      <div @click="detail(menus[6])" v-if="menus[6]" class="sub-page-1"
      :style="background(6)">
      </div>
      <div class="sub-page-2">
        <div @click="detail(menus[7])"  v-if="menus[7]" :style="background(7)">
        </div>
        <div @click="detail(menus[8])" v-if="menus[8]"
          :style="background(8)">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TOKEN_KEY, getToken, removeCookie } from '@/common/auth'
import { getUrlParam } from '@/common/myUtil'
import jwtDecode from 'jsonwebtoken'

export default {
  props: {
    menus: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      meetingId: getUrlParam('meetingId') || 0
    }
  },
  computed: {
    background () {
      return key => {
        return { background: 'url(' + this.menus[key].pageIcon + ') no-repeat center center / cover', borderRadius: '0.32rem' }
      }
    }
  },
  methods: {
    detail (item) {
        if ([1, 2].includes(item.pageDisplayMode)) {
          this.$router.push({
            path: '/detail',
            query: {
              pageId: item.pageId,
              meetingId: item.meetingId
            }
          })
        } else if (item.pageDisplayMode === 3) { // 定制页面
          const token = getToken()
          let info = null
          if (token) {
            info = jwtDecode.decode(token)
          }
          const pageType = JSON.parse(item.pageType)
          if (!pageType.isLogin) { // 不需要登录
            // if (pageType.path === '/register' && info && Number(this.meetingId) === Number(info.meetingId || 0)) { // 如果登录成功 则进入个人中心页面
            //     this.$store.commit('setTabValue', 1)
            //     this.$router.push('/center')
            // } else
            this.$router.push({
              path: `${pageType.path}`,
              query: {
                pageId: item.pageId,
                meetingId: item.meetingId
              }
            })
          } else {
            if (token && Number(this.meetingId) === Number(info.meetingId || 0)) {
              this.$router.push({
                path: `${pageType.path}`,
                query: {
                  pageId: item.pageId,
                  meetingId: item.meetingId
                }
              })
            } else {
              removeCookie(TOKEN_KEY)
              this.$router.push({
                path: `/login?redirect=${encodeURIComponent(pageType.path)}`,
                query: {
                  pageId: item.pageId,
                  meetingId: item.meetingId
                }
              })
            }
          }
        } else if (item.pageDisplayMode === 0) {
          window.location.href = item.pageLink
        }
    }
  }
}
</script>

<style lang="scss" scoped>
.sub-page-layout {
  display: flex;
  justify-content: space-between;
  width: 9rem;
  $gap: .23rem;
  $width: 1.95rem;
  $height: 4.79rem;
  margin: 0 auto;
  padding: 0.43rem 0;

  .sub-page-1 {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: $height;
    padding: 0.43rem;
    border-radius: 0.32rem;

    .title {
      font-size: 0.52rem;
      font-weight: bold;
    }

    &>div {
      &:nth-of-type(1) {
        flex: 1;
        height: $height;
        margin-right: $gap;
      }

      &:nth-of-type(2) {
        flex: 2;
        display: flex;
        flex-direction: column;

        &>div {
          height: 4.2rem;

          &:nth-of-type(2) {
            margin-top: $gap;
          }
        }
      }
    }
  }

  .sub-page-2 {
    display: flex;
    width: 100%;
    margin-top: $gap;

    &>div {
      // padding: 10px;
      flex: 1;
      max-width: calc(50% - .1rem);
      height: 2.4rem;
      border-radius: 0.32rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .img {
        width: 1.43rem;
        height: 1.43rem;
      }

      .title {
        margin-top: $gap;
        font-size: 0.37rem;
      }

      &:nth-of-type(1) {
        margin-right: $gap;
      }
    }
  }

  .sub-page-layout-left,
  .sub-page-layout-right {
    width: 4.3rem;
  }

  .sub-page-layout-left {
    margin-right: $gap;
  }

  .sub-page-layout-right {
    .sub-page-1 {
      margin-top: $gap;
      margin-bottom: $gap;
    }

    .sub-page-2 {
      margin-top: 0;
    }
  }

  .sub-page-3 {
    // display: flex;
    width: 100%;
    height: 2.4rem;
    margin-top: $gap;
    border-radius: 0.32rem;
    padding: 0.22rem;
    align-items: center;
    justify-content: space-around;

    // .title,
    .img {
      // width: 1.43rem;
      height: 1.32rem;
    }

    .title {
      font-size: 0.37rem;
      font-weight: 500;
      height: auto;
      margin-bottom: 4px;
      // width: 1.4rem;
    }
  }
}
</style>
