import ConfirmComponent from '@/components/confirm/index'
import { initializeProps, mergeOptions } from '../pluginHelper'

const plugin = {
  install (Vue) {
    const Confirm = Vue.extend(ConfirmComponent)
    const $vm = new Confirm({
      el: document.createElement('div')
    })
    document.body.appendChild($vm.$el)
    const confirm = {
      show (options) {
        if ($vm.showValue) {
          return
        }
        if (typeof options === 'object') {
          mergeOptions($vm, options)
        } else if (typeof options === 'string') {
          initializeProps($vm)
          $vm.content = options
        }
        if (options && (options.onShow || options.onHide)) {
          this.watcher = $vm.$watch('showValue', (val) => {
            val && options.onShow && options.onShow($vm)
            if (val === false) {
              if (options.onHide) {
                options.onHide($vm)
              }
              this.watcher && this.watcher()
            }
          })
        }
        $vm.$off('on-cancel')
        $vm.$off('on-confirm')
        $vm.$on('on-cancel', () => {
          options && options.onCancel && options.onCancel()
        })
        $vm.$on('on-confirm', () => {
          options && options.onConfirm && options.onConfirm()
        })
        $vm.showValue = true
      },
      hide () {
        $vm.showValue = false
      },
      isVisible () {
        return $vm.showValue
      }
    }
    if (!Vue.confirm) {
      Vue.confirm = confirm
    }
    Vue.mixin({
      created: function () {
        this.$confirm = Vue.confirm
      }
    })
  }
}

export default plugin
export const install = plugin.install
