import loading from './loading/index'
import alert from './alert/index'
import confirm from './confirm/index'
import access from './access/index'
// import toast from './toast/index'

import observer from '../common/observer'

function plugin (Vue) {
    Vue.prototype.$observer = observer
    Vue.use(loading)
    Vue.use(alert)
    Vue.use(confirm)
    Vue.use(access)
    // Vue.use(toast)
}

export default plugin
