/* eslint-disable space-before-function-paren */
import { toString, isNumber } from './util'

export function dateFtt(fmt, date) {
    const o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds(),
        'q+': Math.floor((date.getMonth() + 3) / 3),
        S: date.getMilliseconds()
    }
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
            RegExp.$1,
            (date.getFullYear() + '').substr(4 - RegExp.$1.length)
        )
    }
    for (const k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) {
            fmt = fmt.replace(
                RegExp.$1,
                RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
            )
        }
    }
    return fmt
}

/**
 * 货币格式
 */
export function formatCurrency(num) {
    let noNegative = true
    num = parseFloat((num + '').replace(/[^\d.-]/g, '')).toFixed(2) + ''
    if (parseFloat(num) < 0) {
        num = Math.abs(num).toFixed(2) + ''
        noNegative = false
    }
    const l = num.split('.')[0].split('').reverse()
    const r = num.split('.')[1]
    let t = ''
    for (let i = 0; i < l.length; i++) {
        if (i % 3 === 2 && i !== l.length - 1) {
            t += l[i] + ','
        } else {
            t += l[i] + ''
        }
    }
    return (noNegative ? '' : '-') + t.split('').reverse().join('') + '.' + r
}

/**
 * 获取时间 YY-MM
 */
export function getMyDate() {
    const myDate = new Date()
    let month = myDate.getMonth() + 1
    if (month < 10) {
        month = '0' + month
    }
    return myDate.getFullYear() + '-' + month
}

/**
 * 最高位取整
 */
export function maxCeil(val) {
    if (!isNumber(val)) {
        throw new TypeError('Expected a number')
    }
    const sum = Math.ceil(val)
    const len = toString(sum).length - 1
    return Math.ceil(sum / Math.pow(10, len)) * Math.pow(10, len)
}

/**
 * 获取url数据
 */
export const getUrlParam = (name) => {
    const reg = new RegExp('(^|&|\\?)' + name + '=([^&]*)(&|$)')
    const r = window.location.href.match(reg)
    if (r != null) {
        return unescape(r[2])
    }
    return null
}

/**
 * 滚动条回弹动画
 */
export function scrollAnimation(el, to) {
    const str = 20
    const top = el.scrollTop
    const part = Math.floor((to - top) / str)
    let count = str
    const animation = () => {
        if (count <= 0) {
            el.scrollTo(0, to)
        } else {
            setTimeout(() => {
                --count
                el.scrollTo(0, part * (20 - count) + top)
                animation()
            }, 10)
        }
    }
    animation()
}
// 清除json 中的空节点

export function trimNulls(data) {
    var y
    for (var x in data) {
        y = data[x]
        if (y instanceof Object) y = trimNulls(y)
        if (
            y === 'null' ||
            y === null ||
            y === '' ||
            typeof y === 'undefined' ||
            (y instanceof Object && Object.keys(y).length === 0)
        ) {
            delete data[x]
        }
    }
    return data
}
