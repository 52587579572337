// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/footer-logo.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".meeting[data-v-633597ec]{width:100%;height:100%;overflow:auto;-webkit-overflow-scrolling:touch;font-size:12px}.meeting[data-v-633597ec]::-webkit-scrollbar{display:none}.meeting[data-v-633597ec] .van-notice-bar__left-icon{color:#43c4be}.logo[data-v-633597ec]{margin-top:80px}.banner[data-v-633597ec],.logo[data-v-633597ec],.logo .logo-item[data-v-633597ec]{width:100%}.banner[data-v-633597ec]{height:5.63rem;overflow:hidden;position:relative}.banner[data-v-633597ec] .van-swipe__indicators{bottom:.1rem}.banner[data-v-633597ec] .van-swipe__indicators .van-swipe__indicator--active{background-color:#43c4be}.banner .banner-item[data-v-633597ec]{width:100%;height:100%}.banner .meeting-countDown[data-v-633597ec]{width:5.07rem;height:.64rem;text-align:center;line-height:.64rem;background:rgba(0,0,0,.1);border-radius:.19rem;position:absolute;left:2.48rem;bottom:.13rem;font-size:.37rem;font-weight:500;color:#fff}.menu-list[data-v-633597ec]{width:100%;padding-top:.43rem;position:relative}.menu-list[data-v-633597ec] .van-grid-item__content{padding:0;border-radius:.53rem;overflow:hidden}.menu-item[data-v-633597ec]{width:1.6rem;height:1.6rem}.menu-item-name[data-v-633597ec]{font-size:.37rem;margin-top:6px}.addressinfo[data-v-633597ec]{margin-top:20px;width:100%;color:#fff;display:flex;flex-direction:column;align-items:center;justify-content:center}.addressinfo p[data-v-633597ec]{font-size:15px;margin-bottom:5px}.footer[data-v-633597ec]{width:100%;position:absolute;bottom:.99rem;font-size:.35rem}.footer .footer-host[data-v-633597ec]{text-align:center;margin-bottom:.32rem}.footer .footer-host a[data-v-633597ec]{color:#fff;height:.45rem;text-decoration:underline}.footer .footer-tips[data-v-633597ec]{display:flex;justify-content:center;align-items:center}.footer .footer-tips .footer-logo[data-v-633597ec]{width:1.76rem;height:.45rem;border-radius:.05rem;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%/contain}", ""]);
// Exports
module.exports = exports;
