import LoadingComponent from '@/components/loading/index'

const plugin = {
  install (Vue) {
    const Loading = Vue.extend(LoadingComponent)
    const $vm = new Loading({
      el: document.createElement('div')
    })
    document.body.appendChild($vm.$el)
    const loading = {
      show (options) {
        if ($vm.show) {
          return
        }
        $vm.text = $vm.$options.props.text.default
        if (typeof options === 'string') {
          $vm.text = options
        }
        $vm.show = true
      },
      hide () {
        $vm.show = false
      },
      isVisible () {
        return $vm.show
      }
    }
    if (!Vue.loading) {
      Vue.loading = loading
    }
    Vue.mixin({
      created: function () {
        this.$loading = Vue.loading
      }
    })
  }
}

export default plugin
export const install = plugin.install
