const eventList = {}

function $on (eventType, cb) {
  const event = eventList[eventType]
  if (!event) {
    eventList[eventType] = []
  }
  eventList[eventType].push(cb)
}

function $off (eventType, cb) {
  const event = eventList[eventType]
  if (event) {
    if (cb) {
      event.findIndex((item, index) => {
        if (cb === item) {
          event.splice(index, 1)
        }
      })
    } else {
      eventList[eventType] = []
    }
  }
}

function $emit (eventType, params) {
  const event = eventList[eventType]
  if (!event) return
  event.map((cb) => {
    cb(params)
  })
}

export default {
  $on,
  $off,
  $emit
}
