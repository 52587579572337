let startY = 0
let enabled = false
let supportsPassiveOption = false
try {
  const opts = Object.defineProperty({}, 'passive', {
    get: function () {
      supportsPassiveOption = true
    }
  })
  window.addEventListener('test', null, opts)
} catch (e) {
  // console.log('false')
}

function handleTouchmove (evt) {
  let el = evt.target
  const zoom = window.innerWidth / window.document.documentElement.clientWidth
  if (evt.touches.length > 1 || zoom !== 1) {
    return
  }
  while (el !== document.body && el !== document) {
    const style = window.getComputedStyle(el)
    if (!style) {
      break
    }
    if (el.nodeName === 'INPUT' && el.getAttribute('type') === 'range') {
      return
    }
    const scrolling = style.getPropertyValue('-webkit-overflow-scrolling')
    const overflowY = style.getPropertyValue('overflow-y')
    // let height = parseInt(style.getPropertyValue('height'), 10)
    const isScrollable = scrolling === 'touch' && (overflowY === 'auto' || overflowY === 'scroll')
    const canScroll = el.scrollHeight > el.offsetHeight
    if (isScrollable && canScroll) {
      const height = el.clientHeight
      const curY = evt.touches ? evt.touches[0].screenY : evt.screenY
      const isAtTop = (startY <= curY && el.scrollTop === 0)
      const isAtBottom = (startY >= curY && el.scrollHeight - el.scrollTop === height)
      if (isAtTop || isAtBottom) {
        // evt.preventDefault()
      }
      return
    }
    el = el.parentNode
  }
  // evt.preventDefault()
}

function handleTouchstart (evt) {
  startY = evt.touches ? evt.touches[0].screenY : evt.screenY
}

function enable () {
  window.addEventListener('touchstart', handleTouchstart, supportsPassiveOption ? { passive: false } : false)
  window.addEventListener('touchmove', handleTouchmove, supportsPassiveOption ? { passive: false } : false)
  enabled = true
}

function disable () {
  window.removeEventListener('touchstart', handleTouchstart, false)
  window.removeEventListener('touchmove', handleTouchmove, false)
  enabled = false
}

function isEnabled () {
  return enabled
}

const testDiv = document.createElement('div')
document.documentElement.appendChild(testDiv)
testDiv.style.WebkitOverflowScrolling = 'touch'
const scrollSupport = 'getComputedStyle' in window && window.getComputedStyle(testDiv)['-webkit-overflow-scrolling'] === 'touch'
document.documentElement.removeChild(testDiv)
if (scrollSupport) {
  enable()
}

export default {
  enable,
  disable,
  isEnabled
}
