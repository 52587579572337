class GalleryCache {
  cache

  constructor (target) {
    if (target instanceof Storage) {
      this.cache = target
    } else {
      throw new TypeError('Expected a Storage')
    }
  }

  set (key, value) {
    if (key && value) {
      try {
        this.cache.setItem(key, value)
      } catch (e) {
        console.warn('Exceeding the available storage space')
      }
    }
  }

  setObj (key, value) {
    if (key && value !== undefined && value !== null) {
      try {
        this.cache.setItem(key, JSON.stringify(value))
      } catch (e) {
        console.warn('Exceeding the available storage space')
      }
    }
  }

  get (key) {
    if (key) {
      return this.cache.getItem(key) || undefined
    }
    return undefined
  }

  getObj (key) {
    if (key) {
      let value
      try {
        value = JSON.parse(this.cache.getItem(key) || 'null')
      } catch (e) {
        console.warn('Error JSON.parse()')
      }
      return value === null ? undefined : value
    }
    return undefined
  }

  remove (key) {
    if (key) {
      this.cache.removeItem(key)
    }
  }

  clear () {
    this.cache.clear()
  }
}

export const localCache = new GalleryCache(localStorage)
export const sessionCache = new GalleryCache(sessionStorage)

// 用户账号
export const LOCAL_LOGIN = 'Login'
// Req
export const LOCAL_REQ = 'Req'
// 用户信息
export const USER_INFO = 'UserLogin'
