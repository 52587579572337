/* eslint-disable space-before-function-paren */
import Vue from 'vue'
import store from '../store'
import { getToken } from '../common/auth'
import { localCache } from '@/common/storage'
import { getUrlParam } from '@/common/myUtil'
// 分享朋友
function messageShare (option) {
    return new Promise((resolve, reject) => {
        Vue.wx.onMenuShareAppMessage({
            title: option.title, // 分享标题
            desc: option.desc, // 分享描述
            link: option.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: option.imgUrl, // 分享图标
            success () {
                store.dispatch('MEETING_SHARE_LOGS', {
                    meetingId: option.meetingId,
                    source: 'H5'
                })
                resolve()
            },
            fail (err) {
                console.log(err)
                reject(new Error(err.errMsg))
            }
        })
    })
}

// 分享朋友圈
// function timelineShare(option) {
//     return new Promise((resolve, reject) => {
//         Vue.wx.onMenuShareTimeline({
//             title: option.title, // 分享标题
//             desc: option.desc, // 分享描述
//             link: option.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
//             imgUrl: option.imgUrl, // 分享图标
//             success: function () {
//                 resolve()
//             },
//             fail(err) {
//                 reject(new Error(err.errMsg))
//             }
//         })
//     })
// }
// 选择图片
function chooseImage () {
    return new Promise((resolve, reject) => {
        Vue.wx.chooseImage({
            count: 1,
            sizeType: ['compressed'],
            sourceType: ['album', 'camera'],
            success (res) {
                resolve(res.localIds[0])
            },
            fail (err) {
                reject(new Error(err.errMsg))
            }
        })
    })
}

// 获取 64
function getLocalImgData (url) {
    return new Promise((resolve, reject) => {
        Vue.wx.getLocalImgData({
            localId: url,
            success (res) {
                let localData = res.localData
                if (localData.indexOf('data:image') !== 0) {
                    localData = 'data:image/jpeg;base64,' + localData
                }
                localData = localData.replace(/\r|\n/g, '')
                localData = localData.replace('data:image/jgp', 'data:image/jpeg')
                resolve(localData)
            },
            fail (err) {
                reject(new Error(err.errMsg))
            }
        })
    })
}

// 分享
function share (option) {
    // , timelineShare(option)
    return Promise.all([messageShare(option)])
}

function getImgDataUrl () {
    return new Promise((resolve, reject) => {
        chooseImage()
            .then((res) => {
                return getLocalImgData(res)
            })
            .then((res) => {
                resolve(res)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

function updateShare (option) {
    return new Promise((resolve, reject) => {
        Vue.wx.updateAppMessageShareData({
            title: option.title, // 分享标题
            desc: option.desc, // 分享描述
            link: option.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: option.imgUrl, // 分享图标
            success () {
                // store.dispatch('MEETING_SHARE_LOGS', {
                //     meetingId: option.meetingId,
                //     source: 'H5'
                // })
                resolve()
            },
            fail (err) {
                console.log(err)
                reject(new Error(err.errMsg))
            }
        })
    })
}
/**
 * 自动登录
 */
function autoLogin () {
    const token = getToken() || null
    const openid = localStorage.getItem('openid') || null
    if (!token && openid) {
        store.dispatch('AUTO_LOGIN', {
            appId: openid,
            meetingId: getUrlParam('meetingId') || 0
         }).then(res => {
            console.log(res)
         })
    }
}
/**
 * 获取openId
 */
function getOpenId () {
    const appId = localStorage.getItem('appId')
    const meetingId = getUrlParam('meetingId') || 0
    if (!window.__is_weixin || !appId) {
      return
    }
    localCache.set('meetingId', meetingId)
    // 存储微信配置
    if (!window.__retail_wx_config) {
      window.__retail_wx_config = {}
    }
    // code过期规则：使用过或5分钟内未使用
    const wxCode = getUrlParam('code')
    if (wxCode) {
      window.__retail_wx_config.wxCode = wxCode
    }
    // 如果url中没有code,则跳转微信授权
    if (!wxCode) {
       window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${window.location.href}&response_type=code&scope=snsapi_base&state=STATE&connect_redirect=1#wechat_redirect`
    } else {
      // 获取url上的微信code，换取openid
      if (localStorage.getItem('lastWxCode') === wxCode) {
        // 防止有code情况刷新界面，code过期会报错
        return null
      } else {
        localStorage.setItem('lastWxCode', wxCode)
        // code传给后端，换取openid
        store.dispatch('WECHATPAY_AUTHORIZE', {
            code: wxCode
        }).then(res => {
            localStorage.setItem('openid', res.openid)
            this.login()
        })
      }
    }
}

/**
 * 获取openId并自动登录
 */
function openIdAutoLogin () {
    const token = getToken() || null
    const openid = localStorage.getItem('openid') || null
    const openIdStatus = localStorage.getItem('openIdStatus') || 0
    if (!token && !openid && (Number(openIdStatus) < 2)) {
        localStorage.setItem('openIdStatus', Number(openIdStatus) + 1)
        getOpenId()
    } else if (!token && openid) {
        autoLogin()
    }
}

export { share, getImgDataUrl, updateShare, openIdAutoLogin }
