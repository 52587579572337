/* Layout */
import Layout from '@/layouts'
import meeting from '../views/meeting/meeting.vue'

export default [
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录'
    },
    component: () => import('../views/login.vue')
  },
  {
    path: '/register',
    name: 'register',
    // redirect: '/login',
    meta: {
      title: '报名注册'
    },
    component: () => import('../views/register/index.vue')
  },
  {
    path: '/certificate',
    name: 'certificate',
    meta: {
      title: '证件提交'
    },
    component: () => import('../views/register/certificate.vue')
  },
  {
    path: '/ticket',
    name: 'ticket',
    meta: {
      title: '商家小票'
    },
    component: () => import('../views/ticket/index.vue')
  },
  {
    path: '/register/payment',
    name: 'registerPayment',
    meta: {
      title: '报名支付'
    },
    component: () => import('../views/register/payment.vue')
  },
  {
    path: '/successful',
    name: 'successful',
    meta: {
      title: '支付成功'
    },
    component: () => import('../views/register/successful.vue')
  },
  {
    path: '/registerOrder',
    name: 'registerOrder',
    meta: {
      title: '报名订单'
    },
    component: () => import('../views/register/registerOrder.vue')
  },
  {
    path: '/registerOrder/detail',
    name: 'registerOrderDetail',
    meta: {
      title: '订单详情'
    },
    component: () => import('../views/register/OrderDetail.vue')
  },
  {
    path: '/registerOrder/refund',
    name: 'registerOrderRefund',
    meta: {
      title: '申请退款'
    },
    component: () => import('../views/register/RefundRequest.vue')
  },
  {
    path: '/registerOrder/refund/detail',
    name: 'registerRefundDetail',
    meta: {
      title: '退款详情'
    },
    component: () => import('../views/register/RefundDetail.vue')
  },
  {
    path: '/invoice',
    name: 'invoice',
    meta: {
      title: '申请开票'
    },
    component: () => import('../views/invoice/ApplyInvoice.vue')
  },
  {
    path: '/invoice/headers',
    name: 'InvoiceHeaders',
    meta: {
      title: '我的发票抬头'
    },
    component: () => import('../views/invoice/InvoiceHeaders.vue')
  },
  {
    path: '/invoice/address',
    name: 'InvoiceAddress',
    meta: {
      title: '我的配送地址'
    },
    component: () => import('../views/invoice/InvoiceAddress.vue')
  },
  {
    path: '/',
    name: 'home',
    meta: {
      title: ''
    },
    component: () => import('../views/home/home.vue')
  },
  {
    path: '/meeting',
    component: Layout,
    children: [{
      path: '',
      component: meeting,
      name: 'meeting',
      meta: {
        title: '会议首页'
      }
    }]
  },
  {
    path: '/center',
    component: Layout,
    children: [{
      path: '',
      component: () => import('../views/center/index.vue'),
      name: 'center',
      meta: {
        title: '个人中心'
      }
    }
    ]
  },
  {
    path: '/personal-info',
    name: 'PersonalInfo',
    meta: {
      title: '个人信息'
    },
    component: () => import('../views/center/information.vue')
  },
  {
    path: '/center-list',
    component: () => import('../views/center/list.vue'),
    name: 'center-list',
    meta: {
      title: '管理平台'
    }
  },
  {
    path: '/code',
    name: 'code',
    meta: {
      title: '我的二维码'
    },
    component: () => import('../views/center/BaseCode.vue')
  },
  {
    path: '/detail',
    name: 'detail',
    meta: {
      title: '详情'
    },
    component: () => import('../views/meeting/detail.vue')
  },
  {
    path: '/information',
    name: 'information',
    meta: {
      title: '完善信息'
    },
    component: () => import('../views/meeting/information.vue')
  },
  {
    path: '/infoPassword',
    name: 'infoPassword',
    meta: {
      title: '设置密码'
    },
    component: () => import('../views/meeting/infoPassword.vue')
  },
  {
    path: '/infosuccess',
    name: 'infosuccess',
    meta: {
      title: '信息提交成功'
    },
    component: () => import('../views/meeting/info-success.vue')
  },
  {
    path: '/saleorder',
    name: 'saleorder',
    meta: {
      title: '销售订单'
    },
    component: () => import('../views/saleorder/index.vue')
  },
  {
    path: '/saleorder/list',
    name: 'saleorderList',
    meta: {
      title: '我的销售订单'
    },
    component: () => import('../views/saleorder/OrderList.vue')
  },
  {
    path: '/reception',
    name: 'reception',
    meta: {
      title: '接站服务管理'
    },
    component: () => import('../views/reception/index.vue')
  },
  {
    path: '/receptionsuccess',
    name: 'receptionsuccess',
    meta: {
      title: '接机成功页'
    },
    component: () => import('../views/reception/success.vue')
  },
  {
    path: '/reception/management',
    name: 'receptionManagement',
    meta: {
      title: '接站管理'
    },
    component: () => import('../views/reception/management.vue')
  },
  // {
  //   path: '/eat',
  //   name: 'eat',
  //   meta: {
  //     title: '就餐管理'
  //   },
  //   component: () => import('../views/eat/index.vue')
  // },
  {
    path: '/registration',
    name: 'registration',
    meta: {
      title: '签到管理'
    },
    component: () => import('../views/registration/index.vue')
  },
  {
    path: '/registrationList',
    name: 'registrationList',
    meta: {
      title: '签到详情'
    },
    component: () => import('../views/registration/list.vue')
  },
  {
    path: '/eat',
    name: 'eat',
    meta: {
      title: '会议自助餐用餐'
    },
    component: () => import('../views/eat/eat.vue')
  },
  {
    path: '/eatadminister',
    name: 'eatadminister',
    meta: {
      title: '就餐管理'
    },
    component: () => import('../views/eat/administer.vue')
  },
  {
    path: '/checkin-administer',
    name: 'checkin-administer',
    meta: {
      title: '入住管理'
    },
    component: () => import('../views/manager/CheckinRegistration.vue')
  },
  {
    path: '/table',
    name: 'table',
    meta: {
      title: '会议餐桌用餐'
    },
    component: () => import('../views/eat/table.vue')
  },
  {
    path: '/video',
    name: 'video-live',
    // redirect: '/login',
    meta: {
      title: '视频直播'
    },
    component: () => import('../views/video/index.vue')
  },
  {
    path: '/image',
    name: 'picture-live',
    // redirect: '/login',
    meta: {
      title: '图片直播'
    },
    component: () => import('../views/image/index.vue')
  },
  {
    path: '/lookBack',
    name: 'lookBack',
    // redirect: '/login',
    meta: {
      title: '往期回顾'
    },
    component: () => import('../views/lookBack/index.vue')
  },
  {
    path: '/phonebook',
    name: 'phonebook',
    meta: {
      title: '通讯录'
    },
    component: () => import('../views/phonebook/index.vue')
  },
  {
    path: '/scenicSpots',
    name: 'scenicSpots',
    meta: {
      title: '景点推荐'
    },
    component: () => import('../views/scenicSpot/index.vue')
  },
  {
    path: '/scenicspotdetail',
    name: 'scenicspotdetail',
    meta: {
      title: '景点详情'
    },
    component: () => import('../views/scenicSpot/detail.vue')
  },
  {
    path: '/food',
    name: 'food',
    meta: {
      title: '美食推荐'
    },
    component: () => import('../views/food/index.vue')
  },
  {
    path: '/fooddetail',
    name: 'fooddetail',
    meta: {
      title: '美食详情'
    },
    component: () => import('../views/food/detail.vue')
  },
  {
    path: '/guest',
    name: 'guest',
    meta: {
      title: '演讲嘉宾'
    },
    component: () => import('../views/guest/index.vue')
  },
  {
    path: '/guestdetail',
    name: 'guestdetail',
    meta: {
      title: '嘉宾详情'
    },
    component: () => import('../views/guest/detail.vue')
  },
  {
    path: '/guestschedule',
    name: 'guestschedule',
    meta: {
      title: '嘉宾日程'
    },
    component: () => import('../views/guest/guestSchedule.vue')
  },
  {
    path: '/hotel',
    name: 'hotel',
    meta: {
      title: '预订酒店'
    },
    component: () => import('../views/hotel/index.vue')
  },
  {
    path: '/hoteldetail',
    name: 'hoteldetail',
    meta: {
      title: '酒店详情'
    },
    component: () => import('../views/hotel/detail.vue')
  },
  {
    path: '/hotelorder',
    name: 'hotelorder',
    meta: {
      title: '酒店订单'
    },
    component: () => import('../views/hotel/order.vue')
  },
  {
    path: '/roomAllocation',
    name: 'roomAllocation',
    meta: {
      title: '分房信息'
    },
    component: () => import('../views/hotel/RoomAllocation.vue')
  },
  {
    path: '/hotelorder/detail',
    name: 'hotelorderDetail',
    meta: {
      title: '订单详情'
    },
    component: () => import('../views/hotel/OrderDetail.vue')
  },
  {
    path: '/hotelbook',
    name: 'hotelbook',
    meta: {
      title: '预订酒店'
    },
    component: () => import('../views/hotel/book.vue')
  },
  {
    path: '/hotel/payment',
    name: 'hotelPayment',
    meta: {
      title: '酒店支付'
    },
    component: () => import('../views/hotel/payment.vue')
  },
  {
    path: '/hotel/refund',
    name: 'hotelRefund',
    meta: {
      title: '申请退款'
    },
    component: () => import('../views/hotel/RefundRequest.vue')
  },
  {
    path: '/hotel/refund/detail',
    name: 'hotelRefundDetail',
    meta: {
      title: '退款详情'
    },
    component: () => import('../views/hotel/RefundDetail.vue')
  },
  {
    path: '/exhibitors',
    name: 'exhibitors',
    meta: {
      title: '展商推荐'
    },
    component: () => import('../views/exhibitors/index.vue')
  },
  {
    path: '/exhibitorsdetail',
    name: 'exhibitorsdetail',
    meta: {
      title: '展商详情'
    },
    component: () => import('../views/exhibitors/detail.vue')
  },
  {
    path: '/schedule',
    name: 'schedule',
    meta: {
      title: '会议日程'
    },
    component: () => import('../views/schedule/index.vue')
  },
  {
    path: '/scheduledetail',
    name: 'scheduledetail',
    meta: {
      title: '日程详情'
    },
    component: () => import('../views/schedule/detail.vue')
  },
  {
    path: '/hierarchy',
    name: 'hierarchy',
    meta: {
      title: '组织架构'
    },
    component: () => import('../views/hierarchy/index.vue')
  },
  {
    path: '/seat-table',
    name: 'seat',
    meta: {
      title: '座位信息'
    },
    component: () => import('../views/seat/index.vue')
  },
  {
    path: '/trip',
    name: 'trip',
    meta: {
      title: '出行信息'
    },
    component: () => import('../views/trip/index.vue')
  },
  {
    path: '/process',
    name: 'process',
    meta: {
      title: '会议流程'
    },
    component: () => import('../views/process/index.vue')
  },
  {
    path: '/laborCosts',
    name: 'laborCosts',
    meta: {
      title: '劳务管理'
    },
    component: () => import('../views/laborCosts/index.vue')
  },
  {
    path: '/news',
    name: 'news',
    meta: {
      title: '大会资讯'
    },
    component: () => import('../views/news/index.vue')
  },
  {
    path: '/news-detail',
    name: 'news-detail',
    meta: {
      title: '资讯详情'
    },
    component: () => import('../views/news/detail.vue')
  },
  {
    path: '/product',
    name: 'product',
    meta: {
      title: '产品介绍'
    },
    component: () => import('../views/product/index.vue')
  },
  {
    path: '/product-detail',
    name: 'product-detail',
    meta: {
      title: '产品详情'
    },
    component: () => import('../views/product/detail.vue')
  },
  {
    path: '/vote',
    name: 'vote',
    meta: {
      title: '投票管理'
    },
    component: () => import('../views/vote/index.vue')
  },
  {
    path: '/vote-detail',
    name: 'vote-detail',
    meta: {
      title: '选手详情'
    },
    component: () => import('../views/vote/detail.vue')
  },
  {
    path: '/lottery-turntable',
    name: 'lottery-turntable',
    meta: {
      title: '转盘抽奖'
    },
    component: () => import('../views/lottery/turntable.vue')
  },
  {
    path: '/lottery-sudoku',
    name: 'lottery-sudoku',
    meta: {
      title: '九宫格抽奖'
    },
    component: () => import('../views/lottery/sudoku.vue')
  },
  {
    path: '/lottery-answer',
    name: 'lottery-answer',
    meta: {
      title: '答题抽奖'
    },
    component: () => import('../views/lottery/answer.vue')
  },
  {
    path: '/questionnaire',
    name: 'questionnaire',
    meta: {
      title: '问卷调查'
    },
    component: () => import('../views/questionnaire/index.vue')
  },
  {
    path: '/edit-register',
    name: 'edit-register',
    meta: {
      title: '更改报名信息'
    },
    component: () => import('../views/center/editInformation.vue')
  },
  {
    path: '/longthemegame',
    name: 'longthemegame',
    meta: {
      title: '龙年主题游戏'
    },
    component: () => import('../views/game/longThemeGame.vue')
  },
  {
    path: '/jigsaw-game',
    name: 'jigsaw-game',
    meta: {
      title: '拼图游戏'
    },
    component: () => import('../views/game/jigsaw.vue')
  },
  {
    path: '/running-game',
    name: 'running-game',
    meta: {
      title: '疯狂酷跑'
    },
    component: () => import('../views/game/running.vue')
  },
  {
    path: '/shark-game',
    name: 'shark-game',
    meta: {
      title: '摇一摇'
    },
    component: () => import('../views/game/sharkGame.vue')
  },
  {
    path: '/countMoney-game',
    name: 'countMoney-game',
    meta: {
      title: '疯狂数钞票'
    },
    component: () => import('../views/game/countMoney.vue')
  },
  {
    path: '/tookCoin-game',
    name: 'tookCoin-game',
    meta: {
      title: '接金币'
    },
    component: () => import('../views/game/tookCoin.vue')
  },
  {
    path: '/jump-game',
    name: 'jump-game',
    meta: {
      title: '跳一跳'
    },
    component: () => import('../views/game/jump/jumpGame.vue')
  },
  {
    path: '/404',
    name: '404',
    meta: {
      title: '404'
    },
    component: () => import('../views/404.vue')
  },
  {
    path: '/:patchMatch(.*)',
    redirect: {
      name: '404'
    }
  }
]
