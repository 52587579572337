import { getUsername } from '@/common/auth'

let targetImg = ''

export function buildTargetImg (text) {
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  const scale = window.devicePixelRatio
  canvas.width = 120 * scale
  canvas.height = 120 * scale
  context.scale(scale, scale)
  context.fillStyle = 'transparent'
  context.fillRect(0, 0, 120, 120)
  context.translate(60, 60)
  context.rotate(-30 * Math.PI / 180)
  context.translate(-60, -60)
  context.font = '14px sans-serif'
  context.textAlign = 'center'
  context.textBaseline = 'middle'
  context.fillStyle = '#999'
  context.fillText(text, 60, 60)
  targetImg = context.canvas.toDataURL('image/png')
  return targetImg
}

export default {
  bind (el) {
    function createWatermark (target) {
      const before = document.createElement('div')
      before.style.position = 'absolute'
      before.style.zIndex = '50'
      before.style.top = '0'
      before.style.left = '0'
      before.style.right = '0'
      before.style.bottom = '0'
      before.style.opacity = '0.2'
      before.style.pointerEvents = 'none'
      before.style.backgroundImage = `url("${target}")`
      before.style.backgroundSize = '120px 120px'
      el.style.position = 'relative'
      el.appendChild(before)
      const mo = new MutationObserver(() => {
        before.remove()
        mo.disconnect()
        fire()
      })
      mo.observe(before, {
        attributes: true,
        attributeOldValue: true
      })
    }

    function fire () {
      if (targetImg) {
        createWatermark(targetImg)
      } else {
        const depText = getUsername()
        if (depText) {
          createWatermark(buildTargetImg(depText))
        }
      }
    }

    fire()
  }
}
