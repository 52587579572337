import AlertComponent from '@/components/alert/index'
import { initializeProps, mergeOptions } from '../pluginHelper'

const plugin = {
  install (Vue) {
    const Alert = Vue.extend(AlertComponent)
    const $vm = new Alert({
      el: document.createElement('div')
    })
    document.body.appendChild($vm.$el)
    const alert = {
      show (options) {
        if ($vm.showValue) {
          return
        }
        if (typeof options === 'object') {
          mergeOptions($vm, options)
        } else if (typeof options === 'string') {
          initializeProps($vm)
          $vm.content = options
        }
        if (options && (options.onShow || options.onHide)) {
          this.watcher = $vm.$watch('showValue', (val) => {
            val && options.onShow && options.onShow($vm)
            if (val === false) {
              if (options.onHide) {
                options.onHide($vm)
              }
              this.watcher && this.watcher()
            }
          })
        }
        $vm.showValue = true
      },
      hide () {
        $vm.showValue = false
      },
      isVisible () {
        return $vm.showValue
      }
    }
    if (!Vue.alert) {
      Vue.alert = alert
    }
    Vue.mixin({
      created: function () {
        this.$alert = Vue.alert
      }
    })
  }
}

export default plugin
export const install = plugin.install
