import { isArray } from './isArray'
import { arrayMap } from './arrayMap'

const INFINITY = 1 / 0

export function baseToString (value) {
  if (typeof value === 'string') {
    return value
  }
  if (isArray(value)) {
    return arrayMap(value, baseToString) + ''
  }
  const result = (value + '')
  return (result === '0' && (1 / value) === -INFINITY) ? '-0' : result
}
