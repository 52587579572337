import { isObject } from './isObject'
import { isArray } from './isArray'

export function deepClone (o) {
  if (isArray(o)) {
    const _arr = []
    o.forEach(function (item) {
      _arr.push(deepClone(item))
    })
    return _arr
  } else if (isObject(o)) {
    const _obj = {}
    for (const key in o) {
      // eslint-disable-next-line no-prototype-builtins
      if (o.hasOwnProperty(key)) {
        _obj[key] = deepClone(o[key])
      }
    }
    return _obj
  } else {
    return o
  }
}
