import { mapActions } from 'vuex'

const plugin = {
    install (Vue) {
        Vue.mixin({
            methods: {
                ...mapActions({
                    logAccess: 'MEETING_ACCESS_LOGS' // 访问记录
                }),
                logPageAccess (title) {
                    this.logAccess({
                        meetingId: this.$route.query.meetingId || 0,
                        source: 'H5',
                        action: title || this.$route.meta.title
                    })
                }
            }
        })
    }
}

export default plugin
export const install = plugin.install
