/* eslint-disable space-before-function-paren */
import Vue from 'vue'
import store from '@/store'
import { getUrlParam } from '@/common/myUtil'
import { updateShare, openIdAutoLogin } from './wxApi'
const API_LIST = ['wx-open-launch-weapp']

function wxConfig() {
  const url = location.href.split('#')[0] // window.location.origin //
  return new Promise((resolve, reject) => {
    const _data = {
      url: url
    }
    Vue.req
      .get('https://hxapi.huixie.wang/api/v1/weChat/getWeChat', {
        params: _data
      })
      .then((res) => {
        if (res) {
          localStorage.setItem('appId', res.appId)
          const appId = res.appId
          const timestamp = res.timestamp
          const nonceStr = res.nonce
          const signature = res.signature
          Vue.wx.config({
            debug: false,
            appId: appId,
            timestamp: timestamp,
            nonceStr: nonceStr,
            signature: signature,
            jsApiList: [
              'updateAppMessageShareData', // 分享给朋友以及分享到QQ
              // 'onMenuShareTimeline',// 分享朋友圈
              'onMenuShareAppMessage', // 分享朋友
              'chooseWXPay', // 微信支付
              'scanQRCode' // 微信的扫一扫
            ],
            openTagList: API_LIST
          })
          Vue.wx.ready(() => {
            const meetingId = getUrlParam('meetingId') || 0
            store.dispatch('GET_MEETING', meetingId).then(data => {
              const option = {
                title: data.shareTitle,
                desc: data.shareDesc,
                link: `${window.location.href}`,
                imgUrl: data.shareIcon || data.meetingBanner,
                meetingId: meetingId
              }
              updateShare(option)
              openIdAutoLogin()
            })
            resolve()
          })
          Vue.wx.error((err) => {
            reject(new Error(err.errMsg))
          })
        } else {
          reject(new Error('Error: fail to registration in.'))
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export default wxConfig
