<template>
    <div class="hx-index-layout">
        <div :class="['content', getTabbarStatus ? 'active' : '', isPC ? 'is-PC' : '' ]">
            <transition name="fade-transform" mode="out-in">
                <router-view />
            </transition>
        </div>
        <div :class="{ tabbar: true, pcTabbar: isPC}" v-if="getTabbarStatus">
            <van-tabbar v-model="active" @change="change">
                <van-tabbar-item icon="wap-home">首页</van-tabbar-item>
                <van-tabbar-item
                    :icon="active === 0 ? require('@/assets/images/icons/my-icon.svg') : require('@/assets/images/icons/my-active.svg')">我的</van-tabbar-item>
            </van-tabbar>
        </div>
        <div class="preview-mask" :style="{ opacity: transparency }" v-if="showMark && welcome.meetingWelcomeShow">
            <div class="mark-image" v-if="welcome.meetingWelcomeOpenType === 1">
                <img :src="welcome.meetingWelcomeImg" alt="">
            </div>
            <div class="mark-video" v-if="welcome.meetingWelcomeOpenType === 2">
                <img v-if="welcome.meetingWelcomeBgTop" :src="welcome.meetingWelcomeBgTop" alt="">
                <video ref="welcomeVideo" v-if="welcome.meetingWelcomeVideo" autoplay muted
                    :src="welcome.meetingWelcomeVideo"></video>
                <img v-if="welcome.meetingWelcomeBgBottom" :src="welcome.meetingWelcomeBgBottom" alt="">
            </div>
        </div>
        <div class="meeting-code-mark"
            :style="{ background: welcome.meetingBg ? `url(${welcome.meetingBg}) no-repeat center / cover` : '' }"
            v-if="showCode">
            <div class="code-content"
                :style="{ color: welcome.subPageTitleFontColor ? welcome.subPageTitleFontColor : '#000' }">
                <div>
                    该会议仅通过会议码可见
                </div>
                <div>
                    请输入6位会议码
                </div>
                <div>
                    <input class="code-input" v-model="userCode" maxlength="6" type="text">
                </div>
                <div class="look-meeting" @click="meetingCodeMark">
                    进入会议
                </div>
            </div>
        </div>
    </div>
</template>
<!-- eslint-disable quotes -->
<script>
import { TOKEN_KEY, getToken, removeCookie } from '@/common/auth'
import { getUrlParam } from '@/common/myUtil'
import { localCache, sessionCache } from '@/common/storage'
import jwtDecode from 'jsonwebtoken'
import { mapActions, mapGetters } from 'vuex'
export default {
    name: 'Layout',
    components: {

    },
    mixins: [],
    computed: {
        ...mapGetters(['getTabbarStatus', 'getTabValue'])
    },
    watch: {
        getTabValue (newVal, oldVal) {
            this.active = newVal
        }
    },
    data () {
        return {
            active: Number(sessionCache.get('tabValue') || 0),
            meetingId: getUrlParam('meetingId'),
            welcome: {},
            transparency: 1,
            showMark: false,
            showCode: false,
            userCode: '',
            isPC: false
        }
    },
    methods: {
        ...mapActions({
            getMeeting: 'GET_MEETING'
        }),
        change (active) {
            if (active === 1) {
                const token = getToken()
                const meetingId = getUrlParam('meetingId') || 0
                if (token) { // 个人中心 需要登录
                    const info = jwtDecode.decode(token)
                    if (Number(meetingId) === Number(info.meetingId || 0)) {
                        this.setActive(active)
                        this.$router.push(`/center?meetingId=${meetingId}`)
                    } else {
                        removeCookie(TOKEN_KEY)
                        this.$router.push(`/login?redirect=${encodeURIComponent('/center')}&meetingId=${meetingId}`)
                    }
                } else {
                    this.$router.push(`/login?redirect=${encodeURIComponent('/center')}&meetingId=${meetingId}`)
                }
            } else if (active === 0) {
                this.setActive(active)
                const meetingId = localCache.get('meetingId')
                this.$router.push(`/meeting?meetingId=${meetingId}`)
            }
        },
        initData () {
            this.getMeeting(this.meetingId).then(res => {
                this.welcome = res
                if (res.meetingRole === 1) {
                    this.showCode = false
                    this.markPreview()
                } else if (res.meetingRole === 2) {
                    if (sessionStorage.getItem('code')) {
                        this.showCode = false
                    } else {
                        this.showCode = true
                    }
                }
            })
        },
        setActive (active) {
            this.active = active
            this.$store.commit('setTabValue', active)
        },
        markPreview () {
            const time = 3000
            let videoTime = 0
            this.$nextTick(() => {
                this.showMark = true
            })
            this.transparency = 1
            setTimeout(() => {
                if (this.welcome.meetingWelcomeOpenType === 2 && this.welcome.meetingWelcomeVideo) {
                    const video = this.$refs.welcomeVideo
                    videoTime = video.duration * 1000 - 1000
                    if (navigator.userAgent.indexOf('Android') > 0) {
                        if (this.isAndroid()) {
                            document.addEventListener('click', function () {
                                video.play()
                                this.timer(videoTime, time)
                            })
                        } else {
                            video.play()
                            this.timer(videoTime, time)
                        }
                    } else {
                        video.play()
                        this.timer(videoTime, time)
                    }
                } else {
                    this.timer(1000, time)
                }
            }, 200)
        },
        isAndroid () {
            var ua = navigator.userAgent.toLowerCase()
            if (ua.match(/MicroMessenger/i) === 'micromessenger') {
                return true
            } else {
                return false
            }
        },
        timer (videoTime, time) {
            setTimeout(() => {
                this.transparency = 0
                setTimeout(() => {
                    this.$nextTick(() => {
                        this.showMark = false
                        sessionStorage.setItem('welcome', '已经播放过了')
                     })
                }, time)
            }, videoTime)
        },
        meetingCodeMark () {
            if (!this.userCode || this.userCode.length !== 6) {
                return this.$toast('请输入6位会议码')
            }
            if (this.userCode !== this.welcome.meetingCode) {
                return this.$toast('会议码错误')
            }
            if (this.userCode === this.welcome.meetingCode) {
                this.showCode = false
                sessionStorage.setItem('code', '已经输入过了')
                this.markPreview()
            }
        }
    },
    mounted () {
        if (!sessionStorage.getItem('welcome')) {
            this.initData()
        }
        if (
        !(navigator.userAgent.match(/Mobi/i) ||
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/iPhone/i))
        ) {
            this.isPC = true
        }
    }
}
</script>

<style lang="scss" scoped>
.hx-index-layout {
    height: 100%;
    position: relative;

    .preview-mask {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all 3s;
        width: 100vw;
        height: 100vh;
        background: #000;

        img {
            width: 100%;
        }

        video {
            width: 100%;
        }

        .mark-image {
            width: 100vw;
            height: 100vh;
            display: flex;
            align-items: center;
            img {
                width: 100vw;
                height: 100vh;
                object-fit: cover;
            }
        }

        .mark-video {
            width: 100vw;
            height: 100vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    .meeting-code-mark {
        z-index: 999;
        position: absolute;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-image: linear-gradient(to top, #5ee7df 0%, #b490ca 100%);

        .code-content {
            font-size: 0.6rem;
            text-align: center;

            .code-input {
                margin: 1rem 0;
                height: 1rem;
                border-radius: 1rem;
                border: none;
                outline: none;
                color: #000;
                text-align: center;
                letter-spacing: 5px;
            }

            .look-meeting {
                width: 50%;
                height: 40px;
                border-radius: 6px;
                font-size: 0.5rem;
                font-weight: 700;
                line-height: 40px;
                color: #FFFFFF;
                margin: 0 auto;
                background: linear-gradient(123deg, #43C4BE 0%, #9BD9FF 100%);

            }
        }
    }
}

.content {
    width: 100%;
    height: 100%;

    &.active {
        height: calc(100% - 1.22rem);
    }
}
.is-PC {
    &.active {
        height: calc(100% - 1.22rem);
    }
}

.tabbar {
    height: 50px;
    width: 100%;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.4);
    position: fixed;
    bottom: 0;
}
.pcTabbar {
    width: 10rem;
    box-shadow: none;
    left: 50%;
    transform: translateX(-50%);
    ::v-deep .van-tabbar {
        height: 1.33rem;
        .van-tabbar-item {
            .van-tabbar-item__icon {
                font-size: 0.58rem;
                .van-icon__image {
                    width: 0.58rem;
                    height: 0.58rem;
                }
            }
            .van-tabbar-item__text {
                font-size: 0.32rem;
            }

        }
    }
}

.van-tabbar-item--active {
    color: rgba(67, 196, 190, 1);
}
</style>
