<template>
  <div id="app" :class="{ 'platform': platform }">
    <transition :name="'pop-' + (direction === 'forward' ? 'in' : 'out')">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import preventOverScroll from '@/common/preventOverScroll'
import { mapState, mapActions } from 'vuex'
import { getUrlParam } from '@/common/myUtil'
import wxConfig from '@/config/wxConfig'
import { getToken } from '@/common/auth'
import jwtDecode from 'jsonwebtoken'

export default {
  computed: {
    ...mapState({
      direction: state => state.app.direction
    })
  },
  data () {
    return {
      platform: false,
      meetingId: getUrlParam('meetingId') || 0,
      redirect: getUrlParam('redirect') || null
    }
  },
  methods: {
    ...mapActions({
      getLaborsHide: 'GET_LABORS_HIDE',
      updateToken: 'UPDATE_TOKEN' // 更新token
    })
  },
  beforeCreate () {
  },
  created () {
    const innerHeight = window.innerHeight
    const u = window.navigator.userAgent
    if (/(iPhone|iPad|iPod|iOS)/i.test(u)) {
      this.platform = false
      preventOverScroll.enable()
      window.addEventListener('focusout', () => {
        window.scroll(0, 0)
      })
    } else if (/(Android)/i.test(u)) {
      this.platform = false
      preventOverScroll.disable()
      window.addEventListener('resize', () => {
        const newInnerHeight = window.innerHeight
        if (innerHeight <= newInnerHeight) {
          window.scroll(0, 0)
        }
      })
    } else {
      this.platform = true
      preventOverScroll.disable()
    }
    if (window.__is_weixin) {
      window.onload = () => {
        wxConfig().then(() => {
          console.log('wx sdk')
        })
      }
    }
    const token = getToken()
    if (token) {
        const info = jwtDecode.decode(token)
        if (info.UserStatus * 1 !== 1) { // 审核未通过 更新token
            this.updateToken(this.meetingId).then(res => {
            }).catch(() => {
                this.$toast('系统异常，联系管理员')
            })
        }
        this.getLaborsHide(this.meetingId) // 验证是否为有劳务费的嘉宾
    }
  },
  mounted () {
    // // 刷新页面的时候重新获取一下本地信息到vuex中
    // this.$store.commit('setUserLoginInfo')
    const html = document.getElementsByTagName('html')[0]
    // html.style.fontSize = document.documentElement.clientWidth / 10 + 'px'
    if (
      navigator.userAgent.match(/Mobi/i) ||
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/iPhone/i)
      ) {
        this.$store.commit('setIsPC', false)
        html.style.fontSize = document.documentElement.clientWidth / 10 + 'px'
      } else {
        this.$store.commit('setIsPC', true)
        html.style.fontSize = '37.5px'
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/css/common.scss';

#app {
  height: 100%;
  background: #fff;
  position: relative;
  overflow: hidden;
  font-size: 12px;
}

.platform {
  width: 10rem;
  margin: 0 auto;
}

.pop-out-enter-active,
.pop-out-leave-active,
.pop-in-enter-active,
.pop-in-leave-active {
  will-change: transform;
  -webkit-perspective: 1200px;
  perspective: 1200px;
  // transition: .38s ease-in-out, visibility .38s;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
}

.pop-out-enter {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}

.pop-out-leave-active {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.pop-in-enter {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.pop-in-leave-active {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: #f5f5f5;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #c8c8c8;
}
</style>
