<template>
    <div class="sub-page-layout">
        <div class="sub-page-1">
            <div @click="detail(menus[0])" v-if="menus[0]" class="sub-page-1-1"
            :style="background(0)">
            </div>
            <div class="sub-page-1-2">
                <div @click="detail(menus[1])" v-if="menus[1]" class="page-1-2"
                :style="background(1)">
                </div>
                <div @click="detail(menus[2])" v-if="menus[2]" class="page-1-2"
                :style="background(2)">
                </div>
            </div>
        </div>
        <div class="sub-page-2">
            <div @click="detail(menus[3])" v-if="menus[3]" class="sub-page-2-item"
            :style="background(3)">
            </div>
            <div @click="detail(menus[4])" v-if="menus[4]" class="sub-page-2-item"
            :style="background(4)">
            </div>
        </div>
        <div class="sub-page-3">
            <div class="sub-page-3-left">
                <div @click="detail(menus[5])" v-if="menus[5]" class="page-3-item"
                :style="background(5)">
                </div>
                <div @click="detail(menus[6])" v-if="menus[6]" class="page-3-item"
                :style="background(6)">
                </div>
            </div>
            <div @click="detail(menus[7])" v-if="menus[7]" class="sub-page-3-right"
            :style="background(7)">
            </div>
        </div>
    </div>
</template>
<script>
import { TOKEN_KEY, getToken, removeCookie } from '@/common/auth'
import { getUrlParam } from '@/common/myUtil'
import jwtDecode from 'jsonwebtoken'

export default {
  props: {
    menus: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      meetingId: getUrlParam('meetingId') || 0
    }
  },
  computed: {
    background () {
      return key => {
        return { background: 'url(' + this.menus[key].pageIcon + ') no-repeat center center / cover', borderRadius: '0.32rem' }
      }
    }
  },
  methods: {
    detail (item) {
        if ([1, 2].includes(item.pageDisplayMode)) {
          this.$router.push({
            path: '/detail',
            query: {
              pageId: item.pageId,
              meetingId: item.meetingId
            }
          })
        } else if (item.pageDisplayMode === 3) { // 定制页面
          const token = getToken()
          let info = null
          if (token) {
            info = jwtDecode.decode(token)
          }
          const pageType = JSON.parse(item.pageType)
          if (!pageType.isLogin) { // 不需要登录
            // if (pageType.path === '/register' && info && Number(this.meetingId) === Number(info.meetingId || 0)) { // 如果登录成功 则进入个人中心页面
            //     this.$store.commit('setTabValue', 1)
            //     this.$router.push('/center')
            // } else
            this.$router.push({
              path: `${pageType.path}`,
              query: {
                pageId: item.pageId,
                meetingId: item.meetingId
              }
            })
          } else {
            if (token && Number(this.meetingId) === Number(info.meetingId || 0)) {
              this.$router.push({
                path: `${pageType.path}`,
                query: {
                  pageId: item.pageId,
                  meetingId: item.meetingId
                }
              })
            } else {
              removeCookie(TOKEN_KEY)
              this.$router.push({
                path: `/login?redirect=${encodeURIComponent(pageType.path)}`,
                query: {
                  pageId: item.pageId,
                  meetingId: item.meetingId
                }
              })
            }
          }
        } else if (item.pageDisplayMode === 0) {
          window.location.href = item.pageLink
        }
    }
  }
}
</script>
<style lang="scss" scoped>
   .sub-page-layout {
    display: flex;
    flex-direction: column;
    width: 9rem;
    $gap: .4rem;
    $width: 2.13rem;
    $height: 4.72rem;
    margin: 0 auto;
    padding: 0.43rem 0;

    .sub-page-1 {
        display: flex;
        width: 100%;
        height: $height;

        .sub-page-1-1 {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            padding: 0.33rem;

            .title {
                font-size: 0.46rem;
                font-weight: bold;
                // width: 1.3rem;
            }

            .content {
                width: 100%;
                // height: 1.43rem;
                display: flex;
                justify-content: end;
                align-items: end;

                .img {
                    // width: 1.44rem;
                    width: 100%;
                    height: 2.6rem;
                }
            }
        }

        .sub-page-1-2 {
            .page-1-2 {
                padding: 0.23rem;
                display: flex;
                align-items: center;
                justify-content: space-around;
                width: 100%;

                .title {
                    font-size: 0.46rem;
                    // font-weight: bold;
                }

                .img {
                    width: 2.13rem;
                    height: 1.43rem;
                }
            }
        }

        &>div {
            &:nth-of-type(1) {
                flex: 1;
                height: $height;
                margin-right: $gap;
                border-radius: 0.32rem;
            }

            &:nth-of-type(2) {
                flex: 2;
                display: flex;
                flex-direction: column;

                &>div {
                    height: 3rem;
                    max-width: 5.28rem;
                    max-height: 81px;
                    border-radius: 0.32rem;

                    &:nth-of-type(2) {
                        margin-top: $gap;
                    }
                }
            }
        }
    }

    .sub-page-2 {
        display: flex;
        width: 100%;
        margin-top: $gap;

        .sub-page-2-item {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            justify-content: center;

            .title {
                // width: 1.3rem;
                font-size: 0.46rem;
                // margin-top: 6px;
                // font-weight: bold;
            }

            .img {
                width: 1.43rem;
                height: 1.43rem;
            }
        }

        &>div {
            flex: 1;
            height: 2.4rem;
            max-width: calc(50% - 0.2rem);
            border-radius: 0.32rem;

            &:nth-of-type(1) {
                margin-right: $gap;
            }
        }
    }

    .sub-page-3 {
        display: flex;
        width: 100%;
        height: $height;
        margin-top: $gap;

        .sub-page-3-left {
            .page-3-item {
                display: flex;
                padding: 0.23rem;
                align-items: center;
                justify-content: space-around;

                .title {
                    // width: 1.3rem;
                    font-size: 0.46rem;
                    // font-weight: bold;
                }

                .img {
                    width: 2.13rem;
                    height: 1.43rem;
                }
            }
        }

        .sub-page-3-right {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            padding: 0.33rem;

            .title {
                font-size: 0.46rem;
                font-weight: bold;
                // width: 1.3rem;
            }

            .content {
                width: 100%;
                height: 1.43rem;
                display: flex;
                justify-content: end;
                align-items: end;

                .img {
                    width: 100%;
                    height: 2.6rem;
                }
            }
        }

        &>div {
            &:nth-of-type(2) {
                flex: 1;
                height: $height;
                margin-left: $gap;
                border-radius: 0.32rem;
            }

            &:nth-of-type(1) {
                flex: 2;
                max-width: 5.28rem;
                display: flex;
                flex-direction: column;

                &>div {
                    height: $width;
                    border-radius: 0.32rem;

                    &:nth-of-type(2) {
                        margin-top: $gap;
                    }
                }
            }
        }
    }
}
</style>
