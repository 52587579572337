/* eslint-disable space-before-function-paren */

// token过期时间 默认一天
export const cookieExpires = 1

// 环境变量
const ENV = {
    development: {
        baseURL: 'http://47.96.120.221:8082/api/v1',
        // api: 'http://47.96.120.221:8085/api/v1',
        // api: 'https://hxapi-ts.huixie.wang/api/v1',
        api: 'http://47.96.120.221:8081/api/v1',
        portal: 'http://47.96.120.221:8083/api/v1/',
        newApi: 'http://47.96.120.221:8081/api/v1',
        payApi: 'http://47.96.120.221/play/api/v1'
    },
    alpha: {
        baseURL: 'http://47.96.120.221:8082/api/v1',
        api: 'http://47.96.120.221:8081/api/v1',
        portal: 'http://47.96.120.221:8083/api/v1/',
        newApi: 'http://47.96.120.221:8081/api/v1',
        payApi: 'http://47.96.120.221/play/api/v1'
    },
    prodb: {
        // baseURL: 'https://hxapih5.huixie.wang/api/v1',
        baseURL: 'http://121.43.163.2:8082/api/v1',
        // api: 'https://hxapi.huixie.wang/api/v1',
        api: 'http://121.43.163.2:8081/api/v1',
        portal: 'https://hxapi.portal.huixie.wang/api/v1/',
        newApi: 'https://huixie.wang/meeting/api/v1/',
        payApi: 'https://www.huixie.wang/payment/api/v1/'
    },
    production: {
         baseURL: 'https://hxapih5.huixie.wang/api/v1',
        //  baseURL: 'http://121.43.163.2:8082/api/v1',
         api: 'https://hxapi.huixie.wang/api/v1',
        //  api: 'http://121.43.163.2:8081/api/v1',
        portal: 'https://hxapi.portal.huixie.wang/api/v1/',
        // newApi: 'https://huixie.wang/meeting/api/v1/',
        // newApi: 'http://121.43.163.2:8081/api/v1',
        newApi: 'https://hxapi.huixie.wang/api/v1',
        payApi: 'https://www.huixie.wang/payment/api/v1/'
    }
}

export const NODE_ENV = process.env.NODE_ENV // 环境字段

export function baseURL () {
    return ENV[NODE_ENV].baseURL
}

export function api () {
    return ENV[NODE_ENV].api
}

export function portalURL () {
    return ENV[NODE_ENV].portal
}

export function newApi () {
    return ENV[NODE_ENV].newApi
}
