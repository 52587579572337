function getTarget (node) {
  if (node === undefined) {
    return document.body
  }
  if (typeof node === 'string' && node.indexOf('?') === 0) {
    return document.body
  } else if (typeof node === 'string' && node.indexOf('?') > 0) {
    node = node.split('?')[0]
  }
  if (node === 'body' || node === true) {
    return document.body
  }
  return node instanceof window.Node ? node : document.querySelector(node)
}

function getShouldUpdate (node) {
  if (!node) {
    return false
  }
  if (typeof node === 'string' && node.indexOf('?') > 0) {
    try {
      const config = JSON.parse(node.split('?')[1])
      return config.autoUpdate || false
    } catch (e) {
      return false
    }
  }
  return false
}

const directive = {
  inserted (el, { value }) {
    const parentNode = el.parentNode
    const home = document.createComment('')
    let hasMovedOut = false
    if (value !== false) {
      parentNode.replaceChild(home, el)
      getTarget(value).appendChild(el)
      hasMovedOut = true
    }
    if (!el.__transferDomData) {
      el.__transferDomData = {
        parentNode: parentNode,
        home: home,
        target: getTarget(value),
        hasMovedOut: hasMovedOut
      }
    }
  },
  componentUpdated (el, { value }) {
    const shouldUpdate = getShouldUpdate(value)
    if (!shouldUpdate) {
      return
    }
    const ref$1 = el.__transferDomData
    const parentNode = ref$1.parentNode
    const home = ref$1.home
    const hasMovedOut = ref$1.hasMovedOut
    if (!hasMovedOut && value) {
      parentNode.replaceChild(home, el)
      getTarget(value).appendChild(el)
      el.__transferDomData = Object.assign({}, el.__transferDomData, {
        hasMovedOut: true,
        target: getTarget(value)
      })
    } else if (hasMovedOut && value === false) {
      parentNode.replaceChild(el, home)
      el.__transferDomData = Object.assign({}, el.__transferDomData, {
        hasMovedOut: false,
        target: getTarget(value)
      })
    } else if (value) {
      getTarget(value).appendChild(el)
    }
  },
  unbind (el) {
    if (el.__transferDomData && el.__transferDomData.hasMovedOut === true) {
      el.__transferDomData.parentNode && el.__transferDomData.parentNode.appendChild(el)
    }
    el.__transferDomData = null
  }
}

export default directive
