import { ImagePreview } from 'vant'

/**
 * @param {Array} images
 * @param {?} suffix
 * @returns
 */
export function previewImage (images, suffix = '_raw') {
    if (!Array.isArray(images)) images = [images]
    const imgList = images.map(item => {
        const index = item.lastIndexOf('.')
        const name = item.substring(0, index)
        const format = item.substring(index)
        return new Promise(resolve => {
            const image = new Image()
            image.src = name + suffix + format
            image.onload = () => resolve(name + suffix + format)
            image.onerror = () => resolve(item)
        })
    })
    Promise.all(imgList).then(res => {
        ImagePreview(res)
    })
}
