import Cookies from 'js-cookie'
import { cookieExpires } from '@/config'

// 添加
export function setCookie (key, value) {
    Cookies.set(key, value, { expires: cookieExpires || 1 })
}
// 获取
export function getCookie (key) {
    const value = Cookies.get(key)
    if (value) {
        return value
    } else {
        return undefined
    }
}
// 移除
export function removeCookie (key) {
    Cookies.remove(key)
}

// token
export const TOKEN_KEY = 'token'

export function setToken (token) {
    setCookie(TOKEN_KEY, token)
}

export function getToken () {
    return getCookie(TOKEN_KEY)
}

export function removeToken () {
    removeCookie(TOKEN_KEY)
}

// 水印 and 用户名
export const USERNAME_KEY = 'username'

export function setUsername (name) {
    setCookie(USERNAME_KEY, name)
}

export function getUsername () {
    return getCookie(USERNAME_KEY)
}

export function removeUsername () {
    removeCookie(USERNAME_KEY)
}

// 员工id EMPLOYEE_ID
export const EMPLOYEE_ID = 'employeeId'

export function setEmployeeId (name) {
    setCookie(EMPLOYEE_ID, name)
}

export function getEmployeeId () {
    return getCookie(EMPLOYEE_ID)
}

export function removeEmployeeId () {
    removeCookie(EMPLOYEE_ID)
}

// 员工id originAccount
export const originAccount = 'originAccount'

export function setEmployeeAccount (name) {
    setCookie(originAccount, name)
}

export function getEmployeeAccount () {
    return getCookie(originAccount)
}

export function removeEmployeeAccount () {
    removeCookie(originAccount)
}
// 总经理账户

export const SPECIAL_ROLE = 'specialRole'

export function setSpecialRole (name) {
    setCookie(SPECIAL_ROLE, name)
}

export function getsetSpecialRole () {
    return getCookie(SPECIAL_ROLE)
}

export function removesetSpecialRole () {
    removeCookie(SPECIAL_ROLE)
}
