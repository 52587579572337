<template>
  <div>
    <transition name="mask">
      <div class="alert" v-show="showValue"></div>
    </transition>
    <transition name="dialog">
      <div class="dialog" v-show="showValue">
        <div class="dialog-title">{{title || '提示'}}</div>
        <div class="dialog-hd">
          <div class="text" v-html="content"></div>
        </div>
        <div class="dialog-ft" @click="_onHide">{{buttonText || '确认'}}</div>
      </div>
    </transition>
  </div>
</template>

<script>
  export default {
    name: 'alert',
    props: {
      title: String,
      buttonText: String,
      content: String
    },
    data () {
      return {
        showValue: false
      }
    },
    methods: {
      _onHide () {
        this.showValue = false
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "~@/assets/css/transition.scss";

  .alert {
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
  }

  .dialog {
    position: fixed;
    display: table;
    z-index: 5000;
    width: 80%;
    max-width: 300px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    background-color: #FFFFFF;
    text-align: center;
    border-radius: 3px;
    overflow: hidden;

    .dialog-title {
      font-size: 22px;
      padding: 20px 22px 20px;
    }

    .dialog-hd {
      padding: 0 24px 18px;
      min-height: 50px;
      font-size: 20px;
      line-height: 1.3;
      word-wrap: break-word;
      word-break: break-all;
      color: #999999;
    }

    .dialog-ft {
      font-size: 22px;
      line-height: 60px;
      color: #2FC6A3;
      position: relative;
    }

    .dialog-ft:after {
      content: " ";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      height: 1px;
      border-top: 1px solid #D5D5D6;
      color: #D5D5D6;
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
      -webkit-transform: scaleY(0.5);
      transform: scaleY(0.5);
    }
  }
</style>
