import directive from '@/directive/index'
import http from '@/http/index'
import plugin from '@/plugin/index'
import router from '@/routes/router'
import Vue from 'vue'
// import 'lib-flexible/flexible.js'
import wx from '@/plugin/jssdk'
// import '@/assets/js/rem'
import { common } from '@/views/game/jump/three/utils/common'
import * as THREE from 'three/build/three.min'
import '@/assets/css/reset.css'
import '@/assets/icon/iconfont.css'
import moment from 'moment'
import { previewImage } from '@/common/preview'
import '@vant/touch-emulator'
import {
    ActionSheet,
    Area,
    Button,
    Calendar,
    Collapse, CollapseItem,
    DatetimePicker,
    Dialog,
    Field,
    Form,
    Grid,
    GridItem,
    Icon,
    Image,
    List,
    NavBar,
    NoticeBar,
    Overlay,
    Picker,
    Popover,
    Popup,
    Radio,
    RadioGroup,
    Rate,
    Search,
    Stepper,
    Swipe, SwipeItem,
    Tab,
    Tabbar, TabbarItem,
    Tabs,
    Tag,
    Toast,
    TreeSelect,
    Uploader,
    Switch,
    Checkbox, CheckboxGroup,
    DropdownMenu, DropdownItem,
    Loading,
    Lazyload
} from 'vant'

moment.locale('zh-CN')
window.THREE = common.THREE = THREE
Vue.config.productionTip = false
http(Vue)
plugin(Vue)
directive(Vue)
Vue.prototype.$moment = moment
Vue.prototype.$previewRaw = previewImage
Vue.use(wx)
Vue.use(Grid)
    .use(GridItem)
    .use(Image)
    .use(Field)
    .use(Button)
    .use(TreeSelect)
    .use(Icon)
    .use(Toast)
    .use(Form)
    .use(Area)
    .use(ActionSheet)
    .use(Tabbar)
    .use(TabbarItem)
    .use(DatetimePicker)
    .use(Picker)
    .use(List)
    .use(Swipe)
    .use(SwipeItem)
    .use(NoticeBar)
    .use(Stepper)
    .use(Search)
    .use(Tag)
    .use(Rate)
    .use(Tab)
    .use(Tabs)
    .use(Popup)
    .use(NavBar)
    .use(Popover)
    .use(Uploader)
    .use(Calendar)
    .use(RadioGroup)
    .use(Radio)
    .use(Collapse)
    .use(CollapseItem)
    .use(Overlay)
    .use(Dialog)
    .use(Switch)
    .use(Checkbox)
    .use(CheckboxGroup)
    .use(DropdownMenu)
    .use(DropdownItem)
    .use(Loading)
    .use(Lazyload)
router.run()
